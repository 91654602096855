import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import "./footer.css";

class Footer extends Component {
  render() {
    return (
      <React.Fragment>
        <Container fluid noGutters={true}>
          <Row className="footerbg">
            <Col md={3}>
              <p className="footerSubTitle">
                Copyright {(new Date().getFullYear())} Tata Cancer Care Foundation
                {/* Copyright &#169; 2019. All Rights Reserved. */}
              </p>
            </Col>
            {/* <Col md={2}>
                         <p className="footerTitle"></p>
                       </Col> */}
            <Col md={6} className="text-center">
              <p className="footerSubTitle">
                <a
                  href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/Legal+disclaimer_v2.pdf
"
                  target="_blank"
                >
                  Legal Disclaimer
                </a>{" "}
                |<a href="/contactUs">Contact Us</a> |
                <a href="/careers">Career</a>
              </p>
            </Col>
            {/* <Col md={1}> */}
            {/* <p className="footerSubTitle">FOLLOW US</p> */}
            {/* </Col> */}
            <Col md={3}>
              {/* <a href="https://www.facebook.com/TataTrusts/" target="_blank"><div className="social-icons-ui"><i class="fa fa-2x fa-facebook" aria-hidden="true"></i></div></a>
                         <a href="https://www.youtube.com/channel/UCJZegu67KvGLUpxKleMDTLA" target="_blank"><div className="social-icons-ui"><i class="fa fa-2x fa-youtube-play" aria-hidden="true"></i></div></a>
                         <a href="https://twitter.com/tatatrusts" target="_blank"><div className="social-icons-ui"><i class="fa fa-2x fa-twitter" aria-hidden="true"></i></div></a> */}
              <a target="_blank" href="https://www.adrosonic.com">
                <p className="poweredText">
                  Powered By ADROSONIC<sup>®</sup>
                </p>
              </a>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default Footer;
