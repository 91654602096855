import React, {Component} from 'react';
import { Container, Table } from 'reactstrap';
import { Spinner, Row, Col } from 'reactstrap';
import { InputGroup, InputGroupText, InputGroupAddon, Input } from 'reactstrap';
import Footer from '../../components/footer/footer';
import TitlebarComponent from '../../components/navbar/titlebar';
import Navbar from '../../components/navbar/navbar';
import HomeApi from '../../services/API/HomeApi';
import SubFooter from '../../components/subFooter/subFooter';
import { sortByColumn } from '../functions';
import PaginationComponent from '../../components/pagination/pagination';

class TestsList extends Component {
    constructor(props) {
        super(props);
        this.state = {
          testtableheader: [],
          testtabledata: [],
          loading: false,
          filter: null,
          toggle: false,
          activeColumn: 0,
          lastActiveColumn: 0,
          currentPage: 0,
        }
        this.pageSize = 10;
    }

    componentDidMount(){
      HomeApi.getTestListContent().then(res => {
        //console.log(res);
        // let newArray = res[0].listoftestsoffereddata;
        // newArray.sort(function(a, b) {
        //   var nameA = a.department.toUpperCase(); 
        //   var nameB = b.department.toUpperCase(); 
        //   if (nameA < nameB) {
        //     return -1;
        //   }
        //   if (nameA > nameB) {
        //     return 1;
        //   }
        //   return 0;
        // });
        this.setState({ 
            testtableheader: res[0].listoftestsheaders,
            testtabledata: res[0].listoftestsoffereddata,
            loading: false,
            filter: null
         });
      });
    }

    updateSearch = (inputValue) => {
      let filter = this.state.filter;

      this.setState({
          filter: inputValue
      });
    }

    handleSearchChange = (event) => {
        this.updateSearch(event.target.value);
    }

    filter = (data) => {
      if (!this.state.filter) {
        return data;
      }
        return data.filter((data) => data.test_name.toLowerCase().indexOf(this.state.filter.toLowerCase()) >=0 ||
                                        data.department.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 ||
                                        data.test_spec.toLowerCase().indexOf(this.state.filter.toLowerCase()) >= 0 ||
                                        data.test_charge.toString().indexOf(this.state.filter) >= 0
    )
    }

    handleClick = (title, key) => {
      let arrayCopy = [...this.state.testtabledata];
    if(key === 0 || key === 2 || key === 4){
      if (this.state.activeColumn === key) {
        let toggle = !this.state.toggle
        this.setState({
          toggle: toggle,
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, toggle)
        })
      } else {
        this.setState({
          activeColumn: key,
          testtabledata: sortByColumn(arrayCopy, title, false)
        })
      }
    }
    }
    handlePatientTrackClick = (e, index) => {
    
      e.preventDefault();
  
      this.setState({
        currentPage: index
      });
      
    }


    render() {

      const { currentPage } = this.state;
      const paginationArray = [];
      const paginationNumberArray = [];

      if(this.state.testtabledata) {
        this.state.testtabledata.map((data) => {
          return paginationNumberArray.push(data) 
        })

        this.pagesCount = Math.ceil(paginationNumberArray.length / this.pageSize);

        paginationNumberArray.map((el, key) => {
          el["srno"] = key + 1;
        })
      }

      let isAuthenticated = localStorage.getItem('token') ? true : false;

        let list = <Spinner animation="border" variant="danger" />
        if (!this.state.loading) {
            list = <Table className="testlistTable" striped responsive>
            <thead>
              <tr>
                {this.state.testtableheader ? this.state.testtableheader.map((data, key) => {
                    return <th onClick={() => this.handleClick(data.table_sort_name, key)} className="cursor">{data.table_header}{(key === 0 || key === 2 || key === 4) ? (this.state.toggle && this.state.activeColumn === key) ? "↓": "↑" : ""}</th>
                }) : <Spinner color="danger" />
              }
              </tr>
            </thead>
            <tbody>
              {this.state.testtabledata ? this.filter(this.state.testtabledata).slice(
              currentPage * this.pageSize,
              (currentPage + 1) * this.pageSize
            ).map((data, key) => {
                  return  <tr>
                            <td>{key + 1 + currentPage * this.pageSize}</td>
                            {/* <td>{data.test_id}</td> */}
                            <td>{data.department}</td>
                            <td>{data.test_spec}</td>
                            <td>{data.test_charge}</td>
                            {/* <td>{data.test_duration}</td> */}
                          </tr>
                }) : <Spinner color="danger" />
              }
            </tbody>
          </Table>
        }
        return(
          <React.Fragment>
            <TitlebarComponent loggedinpatient={isAuthenticated} />
               <Navbar />
               <Container style={{marginBottom: '180px'}}>
               <Row className="mt-5">
                 <Col><h2>Lists of Tests Offered</h2>
                 </Col>
               </Row>
               <Row className="mt-5">
                 <Col md="3">
                 <InputGroup size="lg">
                  <InputGroupAddon addonType="prepend">Search</InputGroupAddon>
                  <Input value={this.state.filter} onChange={this.handleSearchChange} type="text" className="coursesSearch"/>
                </InputGroup>
                 </Col>
                 <Col md="9" className="text-right">
                <p>Please find our Test Requisition Form&nbsp;
                <a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/TRF+COP.pdf" target="_blank">
                here</a>
                </p>

                <p>For discounted pricing, please see instructions&nbsp;
                <a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/Discounted+pricing+application+.pdf" target="_blank">
                here</a>
                </p>

                <p>For details on sample collection, please refer our primary sample collection manual&nbsp;
                <a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/Primary_Sample_Collection_Manual.pdf" target="_blank">
                here</a>
                </p>

                <p>Please find our Directory of Services&nbsp;
                <a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/DOS-COP.pdf" target="_blank">
                here</a>
                </p>

                <p>We offer sample pickup services for select regions in the country, please &nbsp;
                <a href="/contactUs" target="_blank">
                contact us</a>&nbsp;for more information
                </p>

                <p>Please give your feedback / suggestion at &nbsp;
                <a href="mailto:qualitycop@tatacancercare.org" target="_blank">qualitycop@tatacancercare.org</a>
                </p>

		<p>Please find our Holiday List (Year-2025)&nbsp;
		<a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/Holiday+List-2025.pdf" target="_blank">
		here
		</a>
		</p>
                
                 </Col>
               </Row>
               <Row className="mt-5 mb-5">
                 <Col className="text-center">
                 {list} 
                 </Col>
               </Row>
               <PaginationComponent currentPage={this.state.currentPage} handleClick={this.handlePatientTrackClick} pagesCount={this.pagesCount} />
             </Container>
             {/* <hr/>
             <SubFooter /> */}
               <Footer />
          </React.Fragment> 
        )
    }
}

export default TestsList;
