import React, { Component } from 'react';
import { Row } from 'reactstrap';
import './navbar.css';

class NavbarComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      activeTabClassName: window.location.pathname,
      openMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  toggleMobileMenu = () => {
    this.setState({ openMenu: !this.state.openMenu });
  };

  render() {
    let isAuthenticated = localStorage.getItem('userTypeId') !== null;
    let userTypeId = localStorage.getItem('userTypeId');

    return (
      <React.Fragment>
        <nav className="menu" onClick={this.toggleMobileMenu}>
          <ol className={this.state.openMenu ? 'mobile_menu' : ''}>
            {userTypeId === '2' && (
              <li className="menu-item">
                <a href="/patientHome" className={this.state.activeTabClassName === '/patientHome' ? 'active' : ''}>
                  Home
                </a>
              </li>
            )}
            {userTypeId === '1' && (
              <li className="menu-item">
                <a href="/creditpartnerHome" className={this.state.activeTabClassName === '/creditpartnerHome' ? 'active' : ''}>
                  Home
                </a>
              </li>
            )}
            {userTypeId === '3' && (
              <li className="menu-item">
                <a href="/traineeHome" className={this.state.activeTabClassName === '/traineeHome' ? 'active' : ''}>
                  Home
                </a>
              </li>
            )}
            <li className="menu-item">
              <a href="/" className={this.state.activeTabClassName === '/' ? 'active' : ''}>
                About Us
              </a>
              <ol className="sub-menu">
                <li className="menu-item">
                  <a href="/aboutCOP">About COP</a>
                </li>
              </ol>
            </li>
            <li className="menu-item">
              <a href="/joinTeam" className={['/joinTeam', '/careers'].includes(this.state.activeTabClassName) ? 'active' : ''}>
                Team
              </a>
            </li>
            <li className="menu-item">
              <a href="#" className={['/testslist', '/antibodies'].includes(this.state.activeTabClassName) ? 'active' : ''}>
                Lab Services
              </a>
              <ol className="sub-menu">
                <li className="menu-item">
                  <a href="/testslist">List of Tests Offered</a>
                </li>
                <li className="menu-item">
                  <a href="/antibodies">List of Antibodies</a>
                </li>
                {!isAuthenticated && (
                  <li className="menu-item ">
                    <a href="\register">Order a Test</a>
                  </li>
                )}
              </ol>
            </li>
            {(userTypeId === '3' || userTypeId === null) && (
              <li className="menu-item">
                <a href="/trainingAcademy" className={this.state.activeTabClassName === '/trainingAcademy' ? 'active' : ''}>
                  Training Academy
                </a>
              </li>
            )}
            <li className="menu-item">
              <a href="#0" className={this.state.activeTabClassName === '/researchMaterial' ? 'active' : ''}>
                Insights
              </a>
              <ol className="sub-menu">
                <li className="menu-item">
                  <a href="/researchMaterial">Research Material</a>
                </li>
              </ol>
            </li>
            <li className="menu-item">
              <a href="/contactUs" className={this.state.activeTabClassName === '/contactUs' ? 'active' : ''}>
                Contact Us
              </a>
            </li>
          </ol>
        </nav>
      </React.Fragment>
    );
  }
}

export default NavbarComponent;
