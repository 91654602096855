import React, {Component} from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TitlebarComponent from '../../components/navbar/titlebar';
import Navbar from '../../components/navbar/navbar';
import { Row, Col, Container, Form, Table, UncontrolledCollapse, Button, CardBody, Card, Alert, FormGroup, Input, Label } from 'reactstrap';
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import './patientHome.css';
import TrackSampleTable from './trackSample/trackSampleTable';
import * as actions from '../../redux/actions/actions';
import HomeApi from '../../services/API/HomeApi';
import ModalComponent from '../../components/modal/modal';
import ModalWishlistComponent from '../../components/modal/modalwishlist';
import PatientOrderHistory from './patientOrderHistory';
import { Spinner } from 'react-bootstrap';
import PatientPaymentForm from './patientPayForm';
import queryString from 'query-string';
import PatientPaymentSuccess from './patientPaymentSuccess';
import PatientPaymentFailure from './patientPaymentFailure';
import PatientPicky from './patientPicky';
import logo from '../../assets/TataTrustsNewLogo.png';

class PatientHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            testDetails: [
                {
                    value: null,
                    selectedSample: '',
                    selectedTests: []
                }
            ],
            form: this.defaultForm(),
            testtableheader: [],
            testtabledata: [],
            modal: false,
            testModal: false,
            toggler1: false,
            toggler2: false,
            toggler3: false,
            toggler4: false,
            address:{
                line1:'',
                line2:'',
                city:'',
                pincode:''
            },
            loading: false,
            visible: false,
            yesToggle: false,
            noLGCharge: false,
            callAddressUpdateAPI: true,
            courierCost: false
            // orderOpenToggler: this.props.location.state.orderOpenToggler,
            // bigList: [{id: 1, name: 'Test Name 1'},{id: 2, name: 'Test Name 2'}, {id: 3, name: 'Test Name 3'} ]
        }
    }

    defaultForm = () => {
        return {
            line1: '',
            line2: '',
            city: '',
            pincode: '',
        }
      }
    
    componentDidMount(){
        // HomeApi.getTestListContent().then(res => {
        //   //console.log(res);
        //   this.setState({ 
        //       testtableheader: res[0].listoftestsheaders,
        //       testtabledata: res[0].listoftestsoffereddata,
        //       loading: false,
        //       filter: null
        //    });
        // });

        this.props.onSampleType();
        this.props.onPatientOrderList(this.props.user.id);
        this.props.onLogisticCost();
        this.props.onGetPatientUpdatedAddress(this.props.user.id);
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.pordersuccess) {
            this.setState({
                testDetails: [
                ],
                address:{
                    line1:'',
                    line2:'',
                    city:'',
                    pincode:''
                },
                loading: false,
                successMsg: true
            })

            //this.props.onPatientOrderList(this.props.user.id);

            // setTimeout(() => {
            //     this.props.onpatientOrderReset();
            // }, 4000);
        } 
        
        if(nextProps.patientupdatedAddsuccess){
            this.setState({
                form: {
                    line1: this.props.patientupdatedAddData ? this.props.patientupdatedAddData.line1 : null,
                    line2: this.props.patientupdatedAddData ? this.props.patientupdatedAddData.line2 : null,
                    city: this.props.patientupdatedAddData ? this.props.patientupdatedAddData.city : null,
                    pincode: this.props.patientupdatedAddData ? this.props.patientupdatedAddData.pincode : null
                }
            })
        }

        if(nextProps.addEditsuccess && this.state.callAddressUpdateAPI) {
            this.setState({
                callAddressUpdateAPI: false
            })

            setTimeout(() => {
                this.props.onGetPatientUpdatedAddress(this.props.user.id);
            }, 2000);
        }
    }

    sampleInputChangedHandler(e, key) {
        
        let id = e.target.value;
        
        if(e.target.value !== "Select Sample"){
        this.props.onSampleTest(id);
        }
        
        const updatedForm = [
            ...this.state.testDetails.slice(0,key),
            Object.assign({}, this.state.testDetails[key], {[e.target.name]: e.target.value}),
            ...this.state.testDetails.slice(key+1)
        ]
        this.setState({
          testDetails: updatedForm
        });
          
    }

    addressInputChangedHandler(e) {

        const updatedForm = {
          ...this.state.address,
          [e.target.name]: e.target.value
        }
        let validationMessages = [];
        this.setState({address: updatedForm, validationMessages});
          
      }

    profileinputChangedHandler(e) {
        const updatedprofileForm = {
          ...this.state.form,
          [e.target.name]: e.target.value
        }
        let validationMessages = [];
        this.setState({
          form: updatedprofileForm, 
          validationMessages
        });
    }

    submitHandler = (event) => {
        //console.log("profile form clicked!");
        event.preventDefault();
    
        const updatedAddress = {
          userId: this.props.user.id,
          address: {
              line1: this.state.form.line1,
              line2: this.state.form.line2,
              city: this.state.form.city,
              pincode: this.state.form.pincode
          }
        }

        this.setState({
            visible: true,
          }, () => {
            window.setTimeout(() => {
              this.props.onGetPatientUpdatedAddress(this.props.user.id);
              this.setState({
                visible: false
              })
            }, 5000)
        });

        this.setState(prevState => ({
            modal: !prevState.modal,
            callAddressUpdateAPI: true
        }));
    
        this.props.onPatientEditAddress(updatedAddress);
        
    }

    submitTestDetails = (event) => {
        event.preventDefault();

        const patientTestListIdArr = this.state.testDetails.map((el) => {
            return el.selectedTests.map(function(subtest) {
                return subtest.lhTestId;
              })
        })

        var patientTestListId = [].concat.apply([],patientTestListIdArr).filter((elem, index, self) => {
            return index === self.indexOf(elem);
        })

        let noLGChargeData = this.state.noLGCharge;
        let islogisticserviceable;
        if(noLGChargeData === false){
            // this.setState({
            //     islogisticserviceable: 1
            // })
            islogisticserviceable= 1
        }
        if(noLGChargeData === true){
            // this.setState({
            //     islogisticserviceable: 0
            // })
            islogisticserviceable= 0
        }

        const patientTestDetails = {
            testList: [...patientTestListId],
            userId: this.props.user.id,
            parceledByCustomer: this.state.courierCost,
            // address:{
            //     line1: this.state.address.line1,
            //     line2:this.state.address.line2,
            //     city:this.state.address.city,
            //     state:this.state.address.state,
            //     pincode:this.state.address.pincode
            // },
            // islogisticserviceable: islogisticserviceable
        }

        //console.log(patientTestDetails);

        this.setState({loading: true});

        this.props.onPatientSampleSubmit(patientTestDetails);
    }

    selectMultipleOption = (value, key) => {
        console.count('onChange')
        //console.log("Val", value);
        const updatedTestDetails = [
            ...this.state.testDetails.slice(0,key),
            Object.assign({}, this.state.testDetails[key], {selectedTests: value}),
           ...this.state.testDetails.slice(key+1)
          ]
        
        this.setState({ testDetails: updatedTestDetails });
    }

    togglemodal = () => {
        this.props.onGetPatientUpdatedAddress(this.props.user.id);
        this.setState(prevState => ({
          modal: !prevState.modal,
          callAddressUpdateAPI: true
        }));
    }

    testToggleModal = () => {
        this.props.onGetPatientUpdatedAddress(this.props.user.id);
        this.setState(prevState => ({
          testModal: !prevState.testModal
        }));
    }

    yescloseToggle = () => {
        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal
        }));

        this.setState({
            yesToggle: true,
            noLGCharge: true,
        })
    }

    nocloseToggle = () => {
        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal
        }));

        this.setState({
            yesToggle: false
        })
    }

    addSample = () => {
        this.setState(previousState => ({
            testDetails: [...previousState.testDetails, {
                value: null,
                selectedSample: '',
                selectedTests: []
            }],
        }));
    }

    toggle = (tabno) => {
        switch(tabno) {
            case 0:
                this.setState(prevState => ({
                    toggler1: !prevState.toggler1
                }));
                break;
            case 1:
                this.setState(prevState => ({
                    toggler2: !prevState.toggler2
                }));
                break;
            case 2:
                this.setState(prevState => ({
                    toggler3: !prevState.toggler3
                }));
                break;
            case 3:
                this.setState(prevState => ({
                    toggler4: !prevState.toggler4,
                    // orderOpenToggler: !prevState.orderOpenToggler
                }));
                break;
        }
    }

    handlePinVerification = (e) => {
        this.setState({
            pincode: e.target.value,
            address: {
                ...this.state.address,
                pincode: e.target.value
            }
        })
    }

    pinVerification = (event) => {
        event.preventDefault();
        
        this.props.onPinVerification(this.state.address.pincode);

        this.setState({
            visible: true
          }, () => {
            window.setTimeout(() => {
              this.setState({
                visible: false
              })
            }, 5000)
          });

        this.setState(prevState => ({
            pinmodal: !prevState.pinmodal,
            noLGCharge: false,
        }))
    }

    removeSample = (item, key) => {

        const updatedTestDetailsafterdelete = this.state.testDetails;

        updatedTestDetailsafterdelete.splice(key, 1);

        this.setState({
            testDetails: updatedTestDetailsafterdelete
        });
    }

    checkTestDetails = (testDetails) => {
        let flag = null;
        
            testDetails.forEach(function (test) {
                if(flag || flag === null) {
                    if(test.selectedSample !== "" && test.selectedTests.length !== 0) {
                        flag = true;
                    }
                    else {
                        flag = false;
                    }
                }   
                });
                
        //console.log(flag);
        return flag;
    
    }

    checkAddressDetails = () => {

        let flag = null;
        if(flag || flag === null){
                if(this.state.form.line1 !== "" && this.state.form.city !== "" && this.state.form.pincode !== "" ) {
                    flag = true;
                }
                else {
                    flag = false;
                }
        }
        //console.log(flag);
        return flag;
    }

    handleCourierCost = () => {
        this.setState(prevState => ({
            courierCost: !prevState.courierCost
        }))
    }

render(){

// let orderOpenTogglerValue = this.props.location.state.orderOpenToggler;

let testListArr = [];

this.state.testDetails.map((el) => {
testListArr.push(el.selectedTests);
})

let testTotalCharge = [].concat.apply([],testListArr).reduce((acc, test) => {
return acc + Number(test.price)
}, 0)

let params = queryString.parse(this.props.location.search);
//console.log(params);


let charge = this.props.lcdata;
let stestdata = this.props.stestdata;
//console.log (this.props.pstatusdata);

//console.log(this.props.patientupdatedAddData);
return(

<React.Fragment>

    {params.paymentStatus === "SUCCESS" ? 
    <PatientPaymentSuccess data={params} userId={this.props.user.id}/> :
    params.paymentStatus === "FAILURE" ? 
    <PatientPaymentFailure data={params} /> :

    this.props.pordermessage ? 
     <PatientPaymentForm message={this.props.pordermessage} loading={this.state.loading}/>:
    // history.push("/patientPayment") :    

    <React.Fragment>
    <TitlebarComponent loggedinpatient={this.props.isAuthenticated ? true : false} />
    <Navbar patientHome={this.props.isAuthenticated ? true : false} />
    <Container>
        <Row className="mt-5">
            <Col>
                <h2>Welcome, {this.props.user.firstName + ' ' + this.props.user.lastName}</h2>
            </Col>
        </Row>
        <Row className="mt-5" style={{cursor: 'pointer'}}>
            <Col>
<div className="profile-tab" id="toggler" onClick={() => this.toggle(0)}>{this.state.toggler1 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i>} Patient Profile </div>
    <UncontrolledCollapse toggler="toggler">
    <Card className="collapse-box">
        <CardBody>
            <Row>
                {/* <Col md={12} className="text-right"><i class="fa fa-edit" onClick={this.togglemodal}></i></Col> */}
                <Col md={6} className="mt-3"><b>Patient Name:</b> {this.props.user.firstName + ' ' + this.props.user.lastName}</Col>
                <Col md={6} className="mt-3"><b>Mobile No.: </b>{this.props.user.contactNo}</Col>
                <Col md={6} className="mt-3"><b>Date of birth:</b> {this.props.user.dob}</Col>
                <Col md={6} className="mt-3"><b>Email Id:</b> {this.props.user.emailId}</Col>
                <Col md={6} className="mt-3"><b>Gender:</b> {this.props.user.gender}</Col>
            </Row><hr/>
            {this.props.patientupdatedAddsuccess ?
            <Row>
                <Col md={12} className="text-right">
                <Button className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}}  onClick={this.togglemodal}>Edit Address</Button>
                </Col>
                <Col md={6} className="mt-3"><b>Address Line 1:</b> {this.props.patientupdatedAddData.line1}</Col>
                <Col md={6} className="mt-3"><b>Address Line 2: </b>{this.props.patientupdatedAddData.line2}</Col>
                <Col md={6} className="mt-3"><b>City:</b> {this.props.patientupdatedAddData.city}</Col>
                {/* <Col md={6} className="mt-3"><b>State:</b> {this.props.user.address.state}</Col> */}
                <Col md={6} className="mt-3"><b>Pincode:</b> {this.props.patientupdatedAddData.pincode}</Col>
            </Row>
            : null }

            <Row className="mt-5">
                <Col md={12}> 
                    {this.props.addEditsuccess ? 
                        <Alert color="success" isOpen={this.state.visible}>{this.props.addEditMessage}</Alert> 
                    : null }
                </Col>
            </Row>

            {/* <Row className="mt-5">
                <Col className="text-center">
                    <Alert color="danger">
                    The final diagnosis report will be generated on the registered patient name ; Incase the order is being placed for a different patient, please register a new patient.
                    </Alert>
                    <Button className="button-lightred" size="md" style={{width: '190px'}} onClick={this.props.ongetReports}>Register New User</Button>{' '}
                </Col>
            </Row> */}

        </CardBody>
    </Card>
    </UncontrolledCollapse>
    </Col>
        </Row>

        <ModalComponent modal={this.state.modal} togglemodal={this.togglemodal} submit={this.submitHandler} title="Edit Address Details" className="patient-profile-modal">
            <Form onSubmit={this.submitHandler}>
                <p className="red-font mb-4">*Fields are mandatory</p>
                <FormGroup>
                <Label for="line1">*Address Line 1</Label>
                <Input required type="text" value={this.state.form.line1} name="line1" placeholder="Line 1" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <FormGroup>
                <Label for="line2">Address Line 2</Label>
                <Input type="text" value={this.state.form.line2} name="line2" placeholder="Line 2" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                <FormGroup>
                <Label for="city">*City</Label>
                <Input required type="text" value={this.state.form.city} name="city" placeholder="City" onChange={(event) => this.profileinputChangedHandler(event)} />
                </FormGroup>
                {/* <FormGroup>
                <Label for="state">State</Label>
                <Input required type="text" value={this.state.form.state} name="state" placeholder="State" onChange={(event) => this.profileinputChangedHandler(event)}/>
                </FormGroup> */}
                <FormGroup>
                <Label for="pincode">*Pincode</Label>
                <Input required type="text" value={this.state.form.pincode} name="pincode" placeholder="Pincode" onChange={(event) => this.profileinputChangedHandler(event)}/>
                </FormGroup>
                <Button className="button-lightred mt-0" size="md" style={{width: '120px'}} disabled={this.checkAddressDetails() ? false : true}>Submit</Button>{' '}
                <Button color="secondary" onClick={this.togglemodal}>Cancel</Button>
            </Form>
        </ModalComponent>

        <ModalWishlistComponent confirmModal={this.state.testModal} togglemodalEnroll={this.testToggleModal} className="trainee-modal">
            <Row>
                <Col md={3} xs={2} className="text-center"><b>Sample Name</b></Col>
                <Col md={6} xs={7} className="text-center"><b>Test Name</b></Col>
                <Col md={3} xs={3} className="text-center"><b>Amount</b></Col>
            </Row><hr/>

            {this.state.testDetails ? this.state.testDetails.map(item => {
                return item ? item.selectedTests.map(data => {
                    return <React.Fragment>
                    <Row className='mt-4'>
                    <Col md={3} xs={2} className="text-center">{data.sample.sampleName}</Col>
                    <Col md={6} xs={7} className="text-center">{data.testName}</Col>
                    {data.price ?
                    <Col md={3} xs={3} className="text-center">&#8377; {(data.price).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</Col>
                    : null }
                    </Row><hr/>
                    </React.Fragment>
                }) : null
                
            }) : <Spinner color="danger" />
            }

            {this.props.patientupdatedAddsuccess ?
                this.props.patientupdatedAddData.islogisticserviceable === 1 && this.props.lcdata && this.state.testDetails.length !== 0 && testTotalCharge !== 0 && this.state.courierCost === false? 
                <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Total Charge: &#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} (Test Charge) + &#8377; {charge.value} (Logistic Charge) = &#8377; {(Number(charge.value) + Number(testTotalCharge)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                :  null
            : null }

            {this.props.patientupdatedAddsuccess ?
                (this.props.patientupdatedAddData.islogisticserviceable === 0 && this.props.lcdata && this.state.testDetails.length !== 0 && testTotalCharge !== 0) || 
                (this.props.patientupdatedAddData.islogisticserviceable === 1 && this.props.lcdata && this.state.testDetails.length !== 0 && testTotalCharge !== 0 && this.state.courierCost === true)? 
                <p className="text-center mt-4" style={{fontWeight: 'bold'}}>Total Charge : &#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> 
                : null
            : null }

            <hr/>
            
            {this.props.patientupdatedAddsuccess && this.state.courierCost === false && this.props.patientupdatedAddData.islogisticserviceable === 1? 
            <React.Fragment>
            <p className="mt-4 ml-3 mr-3" style={{marginBottom: '0px'}}>The sample pick-up is requested from : </p>
            <p className="ml-3 mr-3" style={{fontWeight: 'bold', marginBottom: '0px'}}>{this.props.patientupdatedAddData.line1}, { " " }
            {this.props.patientupdatedAddData.line2 ? this.props.patientupdatedAddData.line2 : null} 
            {this.props.patientupdatedAddData.line2 ? ", " : null}  
            {this.props.patientupdatedAddData.city}, { " " } {this.props.patientupdatedAddData.pincode}.</p>
            <p className="ml-3 mr-3"><i style={{fontSize: '12px'}}>(Please continue if the address is fine or if you need to edit the address for 
            sample pick-up, edit the address in patient profile section.)</i></p><hr/>
            </React.Fragment>
            : null
            } 
            
            {this.props.patientupdatedAddsuccess && this.state.courierCost === false && this.props.patientupdatedAddData.islogisticserviceable === 1 ?  
            <p className="mt-4 ml-3 mr-3"><b>Note: </b>Sample will be pickup within 2 working days, between 4:00 PM - 7:00 PM.</p>
            : null
            }

            <p className="mt-4 ml-3 mr-3">Before confirming order please go through 
            <a href="https://tatatrustbucket.s3.ap-south-1.amazonaws.com/tatatrustspdf/Ordering+a+Test.pdf" target="_blank"> Terms & Conditions</a>
            .</p>

            <Row>
                <Col md={12} className="mt-4 text-center">
                    <Button onClick={this.submitTestDetails} className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}} >Confirm</Button>
                </Col>
            </Row> 

        </ModalWishlistComponent>

        <Row className="mt-3" style={{cursor: 'pointer'}}>


            <Col>
	    /*
                <div className="profile-tab" id="toggler1" onClick={() => this.toggle(1)}>{this.state.toggler2 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i> } Order a test </div>*/
            <UncontrolledCollapse toggler="toggler1">
            <Card className="collapse-box">
                <CardBody>

                {/* <React.Fragment>
                <Row>
                    <Col md={7} className="mt-3">
                        <FormGroup>
                            <Label for="address"><b>Please enter your address for sample pickup:</b></Label>
                        </FormGroup>
                    </Col>
                </Row>
                <Row form>
                    <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
                        <FormGroup>
                        <Label for="email">Line 1</Label>
                        <Input required maxlength="45" type="text" value={this.state.address.line1} name="line1" placeholder="Line 1" onChange={(event) => this.addressInputChangedHandler(event)}/>
                        </FormGroup>
                    </Col>
                    <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
                        <FormGroup>
                            <Label for="address">Line 2</Label>
                            <Input maxlength="45" required type="text" value={this.state.address.line2} name="line2" placeholder="Line 2" onChange={(event) => this.addressInputChangedHandler(event)}/>
                        </FormGroup>
                    </Col>
                    <Col md={3} className="ml-md-3 ml-lg-5 mr-md-3 mr-lg-5">
                    <FormGroup>
                            <Label for="mobile">City</Label>
                            <Input required maxlength="45" type="text" value={this.state.address.city} name="city" placeholder="City" onChange={(event) => this.addressInputChangedHandler(event)} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
                        <FormGroup>
                        <Label for="email">State</Label>
                        <Input required maxlength="45" type="text" value={this.state.address.state} name="state" placeholder="State" onChange={(event) => this.addressInputChangedHandler(event)}/>
                        </FormGroup>
                    </Col>
                    <Col md={3} className="ml-md-3 mr-md-3 mr-lg-5">
                        <FormGroup>
                            <Label for="address">Pincode</Label>
                            <Input required maxlength="6" type="text" value={this.state.address.pincode} name="pincode" placeholder="Pincode" onChange={(event) => this.addressInputChangedHandler(event)}/>
                        </FormGroup>
                    </Col>
                </Row> <hr/>
                </React.Fragment> */}
                
        {this.state.testDetails ? this.state.testDetails.map((item, key) => 
        <React.Fragment>
        <Row>
        <Col md={1}>
            <i class="fa fa-trash mr-1 pr-3 pt-5 pb-5 pull-right" style={{fontSize: '20px'}} onClick={() => this.removeSample(item, key)}></i>
        </Col>
        {this.props.stdata ? 
        <Col md={3} className="mt-3">
            <FormGroup>
                <Label for="exampleSelect"><b>Select Department:</b></Label>
                    <Input type="select" name="selectedSample" id="sampleSelect" onChange={(event) => this.sampleInputChangedHandler(event, key)}>
                    <option>Select Sample</option>
                    {this.props.stdata.map((el) => <option value={el.id}>{el.sampleName}</option> )}    
                    </Input>
            </FormGroup>
        </Col>
        : null }
        {this.props.stestdata ?
        <Col md={3} className="mt-3">
            <Label for="exampleSelect" id="selectTestLabelPatientHome"><b>Select Tests:</b></Label>
            <PatientPicky valueData={item.selectedTests} optionData={this.props.stestdata} selectMultipleOption={this.selectMultipleOption}
            keyValue={key}/>
        </Col>
        : null }
        <Col md={5}>
            {item.selectedTests.length !== 0 ? <Table bordered>
                <thead>
                    <tr>
                    <th>Sr No.</th>
                    <th>Test Name</th>
                    <th>Test Charges</th>
                    </tr>
                </thead>
                <tbody>
                    {item.selectedTests.map((test, key) => 
                    <tr>
                    <td>{key + 1}</td>
                    <td>{test.testName}</td>
                    <td>{test.price}</td>
                    </tr>)
                }
                </tbody>
            </Table> : null 
            }
        </Col>
        </Row>
    </React.Fragment>
        // <PatientPicky stdata={this.props.stdata} removeSample={this.removeSample} sampleInputChangedHandler={this.sampleInputChangedHandler} stestdata={this.props.stestdata}
        //               selectMultipleOption={this.selectMultipleOption} keyValue={key} item={item} testDetails={this.state.testDetails}/> 
        ): null }

        {(testTotalCharge !== 0) ?
        <Row>
            <Col md={6} className="mt-3 text-right">
            </Col>
            <Col md={6} className="mt-3">
                <Row>
                    <Col md={9}><b>Total</b></Col>
                    <Col md={3}><b>&#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</b></Col>
                </Row>

            </Col>
        </Row>
        : null}
                    
      
            <Row>
                <Col md={12} className="mt-3 text-right">
                    <Button className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}}  onClick={this.addSample}>Add Test(s)</Button>{' '}

                </Col>
            </Row>




            {/* <Row>
                <Col md={5} className="mt-3">
                    <FormGroup>
                        <Label for="pincode"><b>Enter your pincode to check if your area is serviceable</b></Label>
                        <Input required maxLength="6" type="text" name="pincode" onChange={(e) => this.handlePinVerification(e)} value={this.state.address.pincode} placeholder="Enter Pincode" />
                    </FormGroup>
                </Col>
                <Col md={2} className="mt-5">
                <Button onClick={(event) => this.pinVerification(event)} className="button-lightred" style={{marginTop: '0', cursor: 'pointer'}} >Verify</Button>
                </Col>
            </Row> */}


                {/* {this.props.pinsuccess ? 
                <Alert color="success" isOpen={this.state.visible}>Verification Successfull, requested area is serviceable.</Alert> 
                : null }

                {this.props.pindata ? 
                <Alert color="danger" isOpen={this.state.visible}>Invalid pincode.</Alert> 
                : null }
                
                {this.props.pinfailure ? 
                <Alert color="danger" isOpen={this.state.visible}>Verification Unsuccessfull, requested area is not serviceable.</Alert>
                : null } <hr/> */}
                
                {/* {this.props.pinsuccess || (this.props.pinfailure && this.state.yesToggle) ?
                 this.state.noLGCharge ? 
                <p className="text-center" style={{fontWeight: 'bold'}}>Total Charge : &#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> :
                <p className="text-center" style={{fontWeight: 'bold'}}>Total Charge: Test Charge + &#8377; {charge.value} (Logistic Charge) = &#8377; {(Number(charge.value) + Number(testTotalCharge)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                : null } */}
                

{this.props.patientupdatedAddsuccess ?
        <Row>
            <Col>
            {this.props.patientupdatedAddData.islogisticserviceable === 0 ?
            <p className="mb-5 mt-5"><b style={{fontSize: '15px'}}>Centre for Oncopathology's sample pickup services are not available at your address, in pincode - {this.props.patientupdatedAddData.pincode}. 
            Please use any courier service to send the sample to the Centre for Oncopathology, Mumbai.</b></p>
            : null }

            {this.props.patientupdatedAddData.islogisticserviceable === 1 ?
            <React.Fragment>
             <p className="mt-5"><b style={{fontSize: '15px'}}>Centre for Oncopathology offers sample pickup services from your address in pincode - {this.props.patientupdatedAddData.pincode}. <br/><br/>
                 If you don’t wish to use our pickup services, Select the "No pickup service required" checkbox below.</b></p>   
            <Row className="mb-5">
            <Col md={12}>
            <FormGroup check>
            <Label check>
                <Input type="checkbox" value={this.state.courierCost} onChange={this.handleCourierCost}/>{' '}
                No pickup service required 
            </Label>

            </FormGroup>
            {/* {this.state.courierCost ?
            <p className="red-font mt-3 ml-3">User to courier on their own.</p>
            : null} */}
            </Col>
            </Row>
            
            </React.Fragment>
            : null }
            </Col>
        </Row>
        : null }

               {this.props.patientupdatedAddsuccess ?
                    this.props.patientupdatedAddData.islogisticserviceable === 1 && this.props.lcdata && this.state.testDetails.length !== 0 && testTotalCharge !== 0 && this.state.courierCost === false? 
                    <p className="text-center mt-3" style={{fontWeight: 'bold'}}>Total Charge: &#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })} (Test Charge) + &#8377; {charge.value} (Logistic Charge) = &#8377; {(Number(charge.value) + Number(testTotalCharge)).toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p>
                    :  null
                : null }

                {this.props.patientupdatedAddsuccess ?
                    (this.props.patientupdatedAddData.islogisticserviceable === 0 && this.props.lcdata && this.state.testDetails.length !== 0 && testTotalCharge !== 0) || 
                    (this.props.patientupdatedAddData.islogisticserviceable === 1 && this.props.lcdata && this.state.testDetails.length !== 0 && testTotalCharge !== 0 && this.state.courierCost === true)? 
                    <p className="text-center mt-3" style={{fontWeight: 'bold'}}>Total Charge : &#8377; {testTotalCharge.toLocaleString(navigator.language, { minimumFractionDigits: 0 })}</p> 
                    : null
                : null }

                {/* {this.props.pinsuccess || (this.props.pinfailure && this.state.yesToggle) ?  */}
                <Row>
                    <Col md={12} className="mt-3 text-center">
                        <Button onClick={this.testToggleModal} disabled={this.checkTestDetails(this.state.testDetails) ? false : true} className="button-lightred" size="md" style={{width: '190px', cursor: 'pointer'}} >Continue</Button>
                    </Col>
                </Row> 
                {/* : null } */}

                {this.state.loading ? 
                <Row>
                <Col md={12} className="mt-3 text-center">
                    <Spinner animation="border" variant="danger" className="text-center" />
                    <p style={{color: 'red'}}>Processing Your Request. Please do not refresh or close the tab.</p>
                </Col>
                </Row>
                    : null}

                {this.props.pordersuccess ? 
                    <h4 className="text-center green-font">Successfully Ordered</h4> : null
                }

            {this.props.pordererror ? 
            <h4 className="text-center" style={{color: 'red'}}>Order not completed. Please try again.</h4>    : null
            }
            </CardBody>
        </Card>
        </UncontrolledCollapse>
        </Col>
    </Row>
        <Row className="mt-3" style={{cursor: 'pointer'}}>
            <Col>
            <div className="profile-tab" id="toggler2" onClick={() => this.toggle(2)}>{this.state.toggler3 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i> } Track Sample </div>
            <UncontrolledCollapse toggler="toggler2">
            <Card className="collapse-box">
                <CardBody>
                    <TrackSampleTable trackData={this.props.phistoryData} pstatusdata={this.props.pstatusdata}/>
                </CardBody>
            </Card>
            </UncontrolledCollapse>
            
            </Col>
        </Row>
        <Row className="mt-3 mb-5" style={{cursor: 'pointer'}}>
            <Col>
            {/* {orderOpenTogglerValue ? 
            <div className="profile-tab" id="toggler3" onClick={() => this.toggle(3)}>{this.state.orderOpenToggler ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i> } Order History </div>
            :  */}
            <div className="profile-tab" id="toggler3" onClick={() => this.toggle(3)}>{this.state.toggler4 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i> } Order History </div>
            {/* } */}
            {/* <div className="profile-tab" id="toggler3" onClick={() => this.toggle(3)}>{this.state.toggler4 ? <i class="fa fa-minus mr-1"></i> : <i class="fa fa-plus mr-1"></i> } Order History </div> */}
            <UncontrolledCollapse toggler="toggler3">
            <Card className="collapse-box">
                <CardBody>
                    <PatientOrderHistory phistoryData={this.props.phistoryData} patientupdatedAddData={this.props.patientupdatedAddData}/>
                </CardBody>
            </Card>
            </UncontrolledCollapse>
            
            </Col>
        </Row>
        
        <Row noGutters={true} className="mb-5">
        <Col xs="12"><img src={logo} className="tthomelogo ml-0 ml-md-5" style={{float: 'right'}}/></Col>
        </Row>
        
        {this.props.pinfailure ?
        <ModalComponent modal={this.state.pinmodal} pinVerification={this.pinVerification} className="trainee-modal">
            <h5>The area is un-serviceable. If you still want to continue, Kindly send sample to XYZ address.</h5>
            <Button color="success" onClick={this.yescloseToggle}>Yes</Button>
            <Button color="danger" onClick={this.nocloseToggle} className="ml-3">No</Button>
        </ModalComponent>
        : null }

    </Container>
    </React.Fragment>
    }
</React.Fragment>
)
}
}

const mapStateToProps = state => {
    return {
        loading: state.loading,
        error: state.error,
        success: state.success,
        data: state.data,
        userTypeId: state.userTypeId,
        isAuthenticated: state.token !== null,
        token: state.token,
        user: state.user,
        pordersuccess: state.pordersuccess,
        pordermessage: state.pordermessage,
        pordererror: state.pordererror,
        pinsuccess: state.pinsuccess,
        pindata: state.pindata,
        pinfailure: state.pinfailure,
        phistoryData: state.phistoryData,
        lcsuccess: state.lcsuccess,
        lcdata: state.lcdata,
        stsuccess: state.stsuccess,
        stdata: state.stdata,
        stestsuccess: state.stestsuccess,
        stestdata: state.stestdata,
        addEditsuccess: state.addEditsuccess,
        addEditMessage: state.addEditMessage,
        patientupdatedAddsuccess: state.patientupdatedAddsuccess,
        patientupdatedAddData: state.patientupdatedAddData,
        pstatusdata: state.pstatusdata,
        // isAuthenticated: state.token !== null,
        // authRedirectPath: state.auth.authRedirectPath
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        ongetReports: () => dispatch(actions.getReports()),
        onPinVerification: (pincode) => dispatch(actions.pinVerification(pincode)),
        onPatientSampleSubmit: (patientTestDetails) => dispatch(actions.patientTestSampleDetails(patientTestDetails)),
        onpatientOrderReset: () => dispatch(actions.patientOrderReset()),
        onPatientOrderList: (id) => dispatch(actions.patientOrderData(id)),
        onLogisticCost: () => dispatch(actions.logisticCost()),
        onSampleType: () => dispatch(actions.sampleType()),
        onSampleTest: (id) => dispatch(actions.sampleTest(id)),
        onPatientEditAddress: (updatedAddress) => dispatch(actions.patientEditAddress(updatedAddress)),
        onGetPatientUpdatedAddress: (id) => dispatch(actions.getPatientUpdatedAddress(id)),
    }
}
  
export default connect(mapStateToProps, mapDispatchToProps)(PatientHome);
